const state = {
  categorySelected: {
    label: '',
    value: '',
    color: '#b8cad4',
  },
  expand: false,
  selectedPinId: null,
  selecteds: [],
  pinForm: {
    userId: null,
    categoryId: null,
    title: '',
    email: '',
    phone: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    zipcode: '',
    description: '',
    imageIds: [],
    link: '',
    facebook: '',
    instagram: '',
    twitter: '',
    whatsapp: '',
  },
  list: [
    {
      userId: null,
      categoryId: null,
      title: '',
      email: '',
      phone: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      zipcode: '',
      description: '',
      lat: null,
      long: null,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRuD1wHotql9_3l0Md18AZBHazV0gWFHzlv7itpkZ6oB1cnLNtc&usqp=CAU',
      facebook: 'https://www.facebook.com',
      instagram: 'https://www.instagram.com',
      twitter: 'https://twitter.com',
      whatsapp: 'https://whatsapp.com',
    },
  ],
  listFiltered: [],
  markers: [],
};

export default state;
