//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LogoCard',
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    goToHome() {
      this.$router.push({ name: 'Home' });
    },
  },
};
